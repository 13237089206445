
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('card-js');

window.Vue = require('vue');

window.events = new Vue();

window.flash = function(message) {
    window.events.$emit('flash',message);
}

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.component('example', require('./components/Example').default);
Vue.component('blog-posts', require('./components/BlogPosts').default);
// Vue.component('checkbox', require('./components/Checkbox').default);
Vue.component('flash', require('./components/Flash.vue').default);
Vue.component('search', require('./components/Search.vue').default);

const app = new Vue({
    el: '#app'
});



// Ödeme Sayfası
$(document).ready(function() {
    $('#countryid').on('change', function () {
        var country_code = $(this).val();
        if (country_code) {
            $.ajax({
                url: '/getStates/' + country_code,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    var len = data.length;
                    if (len > 4) {
                        var dataObject = jQuery.parseJSON(data[0]);
                        var dataObject1 = jQuery.parseJSON(data[1]);
                        var rate = data[2];
                        var taxed = data[3];
                        var total = data[4];
                        $('#provinceid').empty();
                        $('#provinceid').prop('disabled', false);
                        $.each(dataObject, function (key, value) {
                            $('#provinceid').append('<option value="' + key + '">' + value + '</option>');
                        });
                        $('#citiesid').empty();
                        $.each(dataObject1, function (key, value) {
                            $('#citiesid').append('<option value="' + key + '">' + value + '</option>');
                        });
                        $('#rate').empty();
                        $('#rate').append(rate * 100);
                        $('#tax').empty();
                        $('#tax').append(taxed);
                        $('#total').empty();
                        $('#total').append(total);
                    } else {
                        var dataObject = jQuery.parseJSON(data[0]);
                        var rate = data[1];
                        var taxed = data[2];
                        var total = data[3];
                        $('#provinceid').empty();
                        $('#provinceid').prop('disabled', true);
                        $('#citiesid').empty();
                        $.each(dataObject, function (key, value) {
                            $('#citiesid').append('<option value="' + key + '">' + value + '</option>');
                        });
                        $('#rate').empty();
                        $('#rate').append(rate * 100);
                        $('#tax').empty();
                        $('#tax').append(taxed);
                        $('#total').empty();
                        $('#total').append(total);
                    }
                }
            })
        }
    })


// Ödeme Sayfası

    $('#paypalcountry').on('change', function () {
        var country_code = $(this).val();
        if (country_code) {
            $.ajax({
                url: '/getStates/' + country_code,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    var len = data.length;
                    if (len > 4) {
                        var dataObject = jQuery.parseJSON(data[0]);
                        var dataObject1 = jQuery.parseJSON(data[1]);
                        var rate = data[2];
                        var taxed = data[3];
                        var total = data[4];
                        $('#paypalprovince').empty();
                        $('#paypalprovince').prop('disabled', false);
                        $.each(dataObject, function (key, value) {
                            $('#paypalprovince').append('<option value="' + key + '">' + value + '</option>');
                        });
                        $('#paypalcity').empty();
                        $.each(dataObject1, function (key, value) {
                            $('#paypalcity').append('<option value="' + key + '">' + value + '</option>');
                        });
                        $('#rate').empty();
                        $('#rate').append(rate * 100);
                        $('#tax').empty();
                        $('#tax').append(taxed);
                        $('#total').empty();
                        $('#total').append(total);
                    } else {
                        var dataObject = jQuery.parseJSON(data[0]);
                        var rate = data[1];
                        var taxed = data[2];
                        var total = data[3];
                        $('#paypalprovince').empty();
                        $('#paypalprovince').prop('disabled', true);
                        $('#paypalcity').empty();
                        $.each(dataObject, function (key, value) {
                            $('#paypalcity').append('<option value="' + key + '">' + value + '</option>');
                        });
                        $('#rate').empty();
                        $('#rate').append(rate * 100);
                        $('#tax').empty();
                        $('#tax').append(taxed);
                        $('#total').empty();
                        $('#total').append(total);
                    }
                }
            })
        }
    })
    // $("#addcart").click(function(){
    //     currentItems++;
    //     $("#count").text(currentItems);
    // });
})

window.openNav = function() {
    document.getElementById("mySidepanel").style.width = "100%";
}

window.closeNav = function() {
    document.getElementById("mySidepanel").style.width = "0";
}