<template>
  <div>
    <input class="form-control search-box" type="text" placeholder="Search" v-model="query" v-on:keyup.enter="enter">
    <section v-if="results.length > 0 && query" class="search-results">
      <div v-for="result in results.slice(0,10)" :key="result.id">
        <a :href="result.slug">
          <div v-text="result.name"></div>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: null,
      results: []
    };
  },
  watch: {
    query(after, before) {
      this.searchMembers();
    }
  },
  methods: {
    searchMembers() {
      axios.get('/search', { params: { query: this.query } })
          .then(response => this.results = response.data)
          .catch(error => {});
    },
    enter() {

    }
  }
}
</script>

<style lang="scss">
.search-results {
  position: absolute;
  background: #FFF;
  border: 1px solid #dae4e9;
  max-height: 20rem;
  overflow-y: scroll;
  z-index: 1;
  > div {
    margin-bottom: 0.5rem;
    text-align: left;
    &:hover {
      background-color: #F1F5F8;
    }
    a {
      display: block;
      padding: 1rem;
      font-size: 12px;
    }
  }
}
</style>